export default ({organizationId, requestType}) => {
    return ({
        organizationId: organizationId,
        requestType: requestType,
        applianceGroups: [],
        allAppliances: [],
        appliances: [],
        applianceFilters: {},
        activeFilters: {},
        activeApplianceGroup: '',
        appliancesLoading: false,
        noResults: false,
        forceDepotView: false,
        async init() {
            this.applianceGroups = await this.$store.applianceRequestStore.getApplianceGroups(organizationId, requestType);
        },
        getApplianceGroupIdByName(applianceGroupName) {
            const applianceGroup = this.applianceGroups.filter(applianceGroup => applianceGroup.name === applianceGroupName)[0];
            return parseInt(applianceGroup.id);
        },
        /**
         * Pick an appliance and emit an event that is picked up by the applianceDeliveryRequest.registerAppliance() method.
         * The appliance is added to the applianceRequestStore store.
         * The appliances are saved in session storage.
         * @param appliance
         * @returns {string}
         */
        async pickAppliance(appliance) {
            document.getElementById('add-appliance-dialog').close();
            return JSON.stringify(appliance);
        },
        async fetchRelatedApplianceGroups(applianceGroupId) {
            const result = await this.fetch(`/ajax/appliance/getRelatedApplianceGroups/${this.organizationId}/${applianceGroupId}`);

            return result ? result : [];
        },
        async fetchAppliances(requestType, applianceGroupId) {
            this.noResults = false;
            this.appliancesLoading = true;
            this.appliances = [];

            let fetchUrl = '';
            switch (requestType) {
                case 'SUPPLY':
                    fetchUrl = `/ajax/appliance/getScopedAppliancesInDepot/${this.organizationId}/SUPPLY/${applianceGroupId}`;
                    break;
                case 'EXCHANGE':
                    fetchUrl = `/ajax/appliance/getScopedAppliancesInDepot/${this.organizationId}/EXCHANGE/${applianceGroupId}`;
                    break;
                case 'RENTAL':
                    fetchUrl = `/ajax/appliance/getAppliancesInDepotOwnedByMedireva/${this.organizationId}/${applianceGroupId}`;
                    break;
            }

            const result = await this.fetch(fetchUrl);

            if (result !== false) {
                // Map the results and make sure the properties array has lowercase keys and values
                this.allAppliances = result.map(appliance => {
                    if (appliance.properties) {
                        appliance.properties = Object.fromEntries(
                            Object.entries(appliance.properties).map(([k, v]) => {
                                return [k.toLowerCase(), v !== null ? v.toLowerCase() : v];
                            })
                        );
                    }
                    return appliance;
                });
            }

            this.appliances = this.allAppliances;
            if (this.appliances.length === 0) {
                this.noResults = true;
            }
            this.setFilters(this.appliances);
            this.appliancesLoading = false;

            this.activeFilters = {};
        },
        setFilters(appliances) {
            this.applianceFilters = {};
            appliances.forEach((appliance) => {
                for (const propertyName in appliance.properties) {
                    if (appliance.properties[propertyName] === '') {
                        continue;
                    }

                    const key = propertyName,
                        value = appliance.properties[propertyName];
                    if (!this.applianceFilters.hasOwnProperty(key)) {
                        this.applianceFilters[key] = [value];
                    } else if (!Object.values(this.applianceFilters[key]).includes(value)) {
                        this.applianceFilters[key].push(value)
                    }
                    this.applianceFilters[key].sort();
                    this.applianceFilters[key].sort((a, b) => a - b);
                }
            });
            this.applianceFilters = Object.fromEntries(Object.entries(this.applianceFilters).sort())
        },
        filterAppliances(property, selectedValue = null) {
            if (selectedValue === null) {
                this.appliances = this.allAppliances;
                delete this.activeFilters[property];
            } else {
                // Add the selected value to the active filters and sort the active filters by their keys
                this.activeFilters[property] = selectedValue;
                this.activeFilters = Object.fromEntries(Object.entries(this.activeFilters).sort())

                // Filter all appliances and get the ones which have matching properties
                this.appliances = this.allAppliances.filter((appliance) => {
                    const relevantProperties = Object.fromEntries(Object.entries(appliance.properties).filter(([key, value]) => {
                        return Object.keys(this.activeFilters).includes(key)
                    }));
                    return JSON.stringify(relevantProperties) === JSON.stringify(this.activeFilters);
                });
            }
            this.setFilters(this.appliances);
        },
        async fetch(resource, body = {}) {
            let options = {};
            if (Object.keys(body).length !== 0) {
                options.method = 'POST';
                options.headers = {
                    'Content-Type': 'application/json',
                };
                options.body = JSON.stringify(body);
            }
            const response = await fetch(resource, options);

            if (!response.ok) {
                this.$refs.addAppliancesDialog.close();
                let message = '';
                switch (response.status) {
                    case 403:
                        message = 'U bent niet bevoegd om deze data op te halen.';
                        break;
                    case 400:
                        message = 'Uw verzoek kan niet verwerkt worden.';
                        break;
                    default:
                        message = 'Er is een onbekende fout opgetreden.';
                        break;
                }
                this.$dispatch('add-flash-message', {
                    message: message,
                    type: 'error',
                    permanent: true
                });
                throw new Error('Fetch error');
                return false;
            }

            return response.json();
        },
    });
}